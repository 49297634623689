<template>
  <div>
    <v-container>
      <v-row justify="end">
        <v-col cols="8" md="4" class="text-right">
          <v-btn dark small class="gradients" @click="print">
            <v-icon small left>mdi-download</v-icon>
            {{ $t("download") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row justify="end" align="end">
        <v-col cols="12" md="3" class="text-right">
          <v-text-field
            outlined
            dense
            v-model="search"
            class="caption"
            :label="$t('search product')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="3" class="text-right">
          <v-select
            dense
            outlined
            class="caption mr-4"
            :label="$t('select business')"
            item-text="businessName"
            item-value="id"
            :items="ownersBusinesses"
            v-model="selectedBusiness"
            @change="onSelectBusiness"
          ></v-select>
        </v-col>

        <v-col cols="12" md="3" class="text-right">
          <v-select
            dense
            outlined
            class="caption"
            :label="$t('select month')"
            :items="months"
            v-model="selectedMonth"
            @change="onSelectMonth"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" ref="print">
          <v-alert class="pa-4">
            <v-row justify="start">
              <v-col cols="12" md="4">
                <v-img src="@/assets/upsellLogo.png" width="120"></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <h2
                  class="display-1 text-uppercase font-weight-bold text-center"
                >
                  {{ $t(title) }}
                </h2>
              </v-col>
            </v-row>
            <v-row justify="start">
              <v-col cols="12" md="6">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-regular text-capitalize">
                          {{ $t("business name") }} :
                        </span>
                        <span class="subtitle-2">{{ businessName }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-regular text-capitalize">
                          {{ $t("email") }} :
                        </span>
                        <span class="subtitle-2">{{ businessEmail }}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="font-weight-regular text-capitalize">
                          {{ $t("date of report") }} :
                        </span>
                        <span class="subtitle-2">
                          {{ today }}
                        </span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-list-item >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <span class="font-weight-regular text-capitalize">
                                                    report period :
                                                </span> 
                                                <span class="subtitle-2"></span>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item> -->
                </v-list>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :loading="loading"
                  :items="products"
                  :search="search"
                >
                  <template v-slot:[`item.categoryID`]="{ item }">
                    {{ getCategoryName(item.categoryID) }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { format } from "date-fns";

import db from "@/plugins/fb";

export default {
  data: () => ({
    days: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    selectedMonth: "",
    selectedBusiness: "",
    businessName: "",
    monthStock: "",
    businessEmail: "",
    products: [],
    categories: [],
    loading: true,
    search: "",
  }),

  computed: {
    currentBusiness() {
      return this.$store.getters.getCurrentBusiness; //selected after login
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },

    ownersBusinessIds() {
      return this.$store.getters.getBusinessId;
    },

    title() {
      return this.$t("stock balance report");
    },
    today() {
      return format(new Date(), "yyyy-MM-dd");
    },
    headers() {
      return [
        {
          text: this.$t("product name"),
          value: "name",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("product barcode"),
          value: "barcode",
          class: "blue-grey darken-3 white--text font-weight-medium",
        },
        {
          text: this.$t("category"),
          value: "categoryID",
          class: "blue-grey darken-3 white--text font-weight-medium",
        },
        {
          text: this.$t("in stock"),
          value: "quantity.inStock",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
        {
          text: this.$t("valuation"),
          value: "price.selling",
          class: "blue-grey darken-3 white--text font-weight-regular",
        },
      ];
    },
  },

  created() {
    this.getStock();
  },

  methods: {
    print() {},
    getStock() {
      if (this.selectedBusiness) {
        db.collection("products")
          .where("businessID", "==", this.selectedBusiness)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.products.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
            this.getCategories();
          });
      } else {
        db.collection("products")
          .where("businessID", "in", this.ownersBusinessIds)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.products.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.loading = false;
            this.getCategories();
          });
      }
      console.log(this.products);
    },

    getCategories() {
      db.collection("productCategory")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categories.push({
              id: doc.id,
              category: doc.data().category,
            });
          });
        });
    },

    getCategoryName(id) {
      let catId = this.categories.find((item) => item.id == id);
      let category = catId.category;

      return category;
    },

    onSelectBusiness() {
      //populate income statement title
      this.businessName = this.selectedBusinessName();

      this.businessEmail = this.selectedBusinessEmail();
      this.products = [];
      //get stock
      db.collection("products")
        .where("businessID", "==", this.selectedBusiness)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.products.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.loading = false;
          this.getCategories();
        });
    },

    //function called when month is changed
    onSelectMonth() {
      //pass the selected month to a filter function
      //this.monthFilteredStock(this.selectedMonth);
    },
    // monthFilteredStock(currentMonth) {
    //   let monthStock = this.products.filter(
    //     (item) => format(item.dateTime.toDate(), "MMMM") == currentMonth
    //   );
    //   //item.dateTime.toDate().getMonth()
    //   this.monthStock = monthStock.sort((a, b) =>
    //     a.dateTime < b.dateTime ? 1 : -1
    //   );
    //   console.log(this.monthStock);
    // },

    selectedBusinessName() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.businessName;
    },
    selectedBusinessEmail() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.email;
    },
  },
};
</script>

<style></style>
